<template>
  <div
       :class="{ 'shrinkreport-content': drawer && !isMobile }"
       style="margin-top: 65px; padding: 5px">

    <Breadcrumbs style="display:inline-block;" />
    <v-container fluid class="ml-4">
      <div v-if="visibleNews.length == 0">{{ $t("news.news_not_available") }}</div>
      <div v-else>
        <ul>
          <li v-for="news of visibleNews" v-bind:key="news.id">
            <router-link class="newsAll-container" :to="'/news/' + news.id">
              {{ news.title }}<br/>
              {{ moment(news.createdAt).format('YYYY-MM-DD HH:mm') }}
              
          </router-link>
          </li>
        </ul>
      </div>
      <v-pagination  v-if="pagesCount > 1"
                    v-model="page"
                    :length="pagesCount"
                    circle></v-pagination>
    </v-container>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Breadcrumbs from "@/components/Breadcrumbs";
import authHeader from "@/services/auth-header";
import constants from "@/constants";
const moment = require('moment');

const axios = require('axios');
var API_URL = constants.API_URL + "news/";

export default {
  name: "News",
  components: {
    Breadcrumbs
  },
  watch: {
    page() {
      this.$refs.newsContainer.scrollTo({ top: 0, behavior: "smooth" });
    }
  },
  data: () => {
    return {
      news: null,
      loadingNews: true,
      page: 1,
      perPage: 24
    };
  },
  computed: {
    ...mapState({
      isMobile: (state) => state.isMobile,
      drawer: (state) => state.drawer,
    }),
    visibleNews() {
      if (!this.news) return [];
      return this.news.slice((this.page - 1) * this.perPage, this.page * this.perPage)
    },
    pagesCount() {
      if (!this.news) {
        return 0;
      }
      return Math.ceil(this.news.length / this.perPage);
    }
  },
  methods: {
    ...mapActions({
    }),
    moment,
    async reloadNews() {
      try {
        this.loadingNews = true;

        this.page = 1;
        let res = await axios.get(API_URL + "?language=" + this.$i18n.locale, { headers: authHeader() });

        if (res && res.data) {
          this.news = res.data.news;

          for (let news of this.news) {
            news.thumbnailUrl = '123';
          }
        }
      }
      catch (err) {
        alert(err);
      }
      finally {
        this.loadingNews = false;
      }
    },
  },
  async mounted() {
    this.reloadNews();

    this.$store.dispatch("setBreadcrumbs", [
      { text: this.$t("news.news") }
    ]);
  },
};
</script>

<style scoped>
.v-chip {
  cursor: pointer;
}

.v-chip.selected {
  background: #009f4d;
  color: white;
}

.v-chip+.v-chip {
  margin-left: 10px;
}

.newsAll-container {
  display: block;
  position: relative;
  cursor: pointer;
  text-decoration: none;
}

.newsAll-container:hover {
  opacity: 0.8;
}

.newsAll-container img {
  max-width: 100%;
}

.news-container {
  max-height: unset;
  overflow: auto;
}

.not-available {
  text-align: center;
  background-color: #8080805e;
  width: calc(100% - 10px);
  margin-left: 5px;
  aspect-ratio: 4 / 3;
}

.not-available>div {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)
}

.not-available-text {
  color: #5C5C5C;
  font-weight: bold;
  font-size: 1em;
  margin-top: 15px;
}

.news-title,
.news-subtitle {
  text-align: left;
  margin-left: 5px;
  word-break: break-all;
}

.news-title {
  font-weight: bold;
}

.newsAll-container .v-chip {
  position: absolute;
  right: 3px;
  bottom: 8px;
}

@media (min-width: 1000px) {
  .news-container {
    max-height: calc(100vh - 200px);
  }
}

img.hover {
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, .3), 0 6px 10px 0 rgba(0, 0, 0, .14), 0 1px 18px 0 rgba(0, 0, 0, .2) !important;
}

.news_wrapper {
  position: relative;
  padding-bottom: 75%;
}

.news-thumbnail {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>